<template>
  <header>
    <m-page-header :pageTitle="pageTitle" @upDate="upDate" />
  </header>
  <main>
    <BasicConfigure v-if="isActive === 0" />
    <!--    <ClassifyingMap v-else />-->
  </main>
</template>

<script>
import MPageHeader from '../components/pageHeader/PageHeader.vue';
import BasicConfigure from '../components/basicConfigure/Vision';
//import ClassifyingMap from '../components/classifyingMap/Index';
import { ref } from 'vue';
export default {
  name: 'VisionSetting',
  components: {
    'm-page-header': MPageHeader,
    BasicConfigure,
    //ClassifyingMap,
  },
  setup() {
    const pageTitle = ref([
      {
        text: '基础配置',
        isavtive: true,
      },
      /* {
        text: '分类映射',
        isavtive: false,
      },*/
    ]);
    const isActive = ref(0);

    function upDate(index) {
      isActive.value = index;
      pageTitle.value.map(res => {
        res.isavtive = false;
      });
      pageTitle.value[index].isavtive = true;
    }

    return {
      pageTitle,
      upDate,
      isActive,
    };
  },
};
</script>

<style scoped></style>
