<template>
  <div class="view">
    <m-form-skeleton :loading="fromLoading">
      <el-form :model="formInput" :rules="rules" ref="ruleForm">
        <div v-if="isDev">
          <div class="serach_icon">凭证配置</div>
          <div class="icon_list">
            <div class="input_from">
              <span>
                <el-form-item prop="credentials">
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入凭证配置"
                    v-model="formInput.credentials"
                  />
                </el-form-item>
              </span>
            </div>
          </div>
        </div>
        <div class="serach_icon">
          启用状态
          <el-tooltip content="前台搜图功能启用状态" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <el-switch v-model="formInput.status" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </div>
        </div>
        <div class="serach_icon">
          是否显示标题
          <el-tooltip content="是否显示标题" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <el-switch
              v-model="formInput.title_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </div>
        </div>
        <div class="serach_icon">
          是否显示货号
          <el-tooltip content="是否显示货号" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <el-switch
              v-model="formInput.code_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </div>
        </div>
        <div class="serach_icon">
          图片上传模式
          <el-tooltip content="图片上传模式" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <el-radio v-model="formInput.image_mode" label="1">主图模式</el-radio>
            <el-radio v-model="formInput.image_mode" label="2">颜色模式</el-radio>
          </div>
        </div>
        <div class="serach_icon">
          bucket桶名称
          <el-tooltip content="bucket桶名称" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="bucket_name">
                <el-input v-model="formInput.bucket_name" placeholder="请输入bucket桶名称">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          查询缓存时间(分钟)
          <el-tooltip content="查询缓存时间(分钟)" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="cache_time">
                <el-input
                  type="number"
                  v-model="formInput.cache_time"
                  placeholder="查询缓存时间(分钟)"
                >
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          QPS限制
          <el-tooltip content="QPS限制" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="qps_limit">
                <el-input type="number" v-model="formInput.qps_limit" placeholder="QPS限制">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          查询最大数量
          <el-tooltip content="查询最大数量" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="page_size">
                <el-input
                  type="number"
                  v-model="formInput.page_size"
                  placeholder="请输入查询最大数量"
                >
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          查询阀值
          <el-tooltip content="查询阀值" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="score">
                <el-input v-model="formInput.score" placeholder="查询阀值"> </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          部件阀值
          <el-tooltip content="部件阀值" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="part_score">
                <el-input v-model="formInput.part_score" placeholder="部件阀值"> </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          商品集名称
          <el-tooltip content="商品集名称" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="product_set_name">
                <el-input v-model="formInput.product_set_name" placeholder="默认(例:cn01-30611))">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
      </el-form>
    </m-form-skeleton>
  </div>
  <div class="saveBtn" v-show="!fromLoading">
    <el-button type="primary" @click="save" :loading="loading">保存</el-button>
    <el-button type="success" @click="create" :loading="loading">生成商品集</el-button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { saveBase, Config, createProductSet } from '../../api/BasicSetting';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';

export default {
  name: 'Index',
  setup() {
    const route = useRoute();
    const isDev = ref(route.query.dev); //是否开发者
    const rules = ref({
      credentials: [{ required: true, message: '请输入凭证配置', trigger: 'blur' }],
      bucket_name: [{ required: true, message: '请输入桶名称', trigger: 'blur' }],
    });
    const loading = ref(false);
    const fromLoading = ref(true);
    const formInput = ref({
      credentials: '', //凭证配置
      bucket_name: '', //桶名称
      image_mode: '1', //图片上传模式
      status: true,
      title_status: true,
      code_status: true,
      score: '',
      part_score: '', //部件阀值
      cache_time: '',
      qps_limit: '',
    });
    const getConfig = async () => {
      fromLoading.value = true;
      const { data } = await Config({ type: 2 });
      data.param.status = data.status == 1 ? true : false;
      data.param.code_status = data.param.code_status == 1 ? true : false;
      data.param.title_status = data.param.title_status == 1 ? true : false;
      formInput.value = data.param;
      fromLoading.value = false;
    };
    const ruleForm = ref();
    const save = () => {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          formInput.value.type = 2;
          saveBase(formInput.value)
            .then(res => {
              loading.value = false;
              if (res.status == 1) {
                ElMessage.success({
                  message: '操作成功',
                  type: 'success',
                });
                getConfig();
              }
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    const create = () => {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          createProductSet({
            credentials: formInput.value.credentials,
            product_set_name: formInput.value.product_set_name,
          })
            .then(res => {
              loading.value = false;
              if (res.status == 1) {
                ElMessage.success({
                  message: '生成商品集成功',
                  type: 'success',
                });
              }
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return {
      rules,
      loading,
      fromLoading,
      getConfig,
      ruleForm,
      save,
      formInput,
      create,
      isDev,
    };
  },
  mounted() {
    this.getConfig();
  },
};
</script>

<style scoped lang="scss">
@import '../../statics/scss/iconfont';
.view {
  padding: 15px;
  .serach_icon {
    padding: 20px 0;
  }
  .icon_list {
    background-color: #f6f6f6;
    padding: 20px;
    box-sizing: border-box;
    .el-form-item {
      margin: 0;
    }
    .input_from {
      display: flex;
      align-items: center;
      > span:first-child {
        margin-right: 15px;
      }
    }
    > span {
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 2px;
      border: solid 1px #d7dde3;
      background-color: #fff;
      margin-right: 13px;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
      i {
        color: #666;
        font-size: 30px;
      }
    }
    .icon_active {
      border: 1px solid #666698;
      i {
        color: #666698;
      }
    }
  }
  ::v-deep(textarea) {
    width: 500px;
  }
}
.saveBtn {
  background-color: #f6f6f6;
  padding: 15px;
  box-sizing: border-box;
  button {
    width: 187px;
  }
}
</style>
